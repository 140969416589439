.locationDetail {
    box-shadow: 0px 10px 5px #e0e0e0;
    width: 100%;
}

.locationDetail .eds-table-heading {
    font-size: small;
    font-style: italic;
    font-weight: bold;
}

.locationDetail .eds-table-key-column {
    font-size: small;
    font-weight: bold;
    color: #707072;
}

.locationDetail .eds-table-cell {
    font-size: small;
}