
table { border-collapse: collapse; width: 100%; }
th, td { background: #fff; padding: 8px 16px; }

.tableFixHead {
    overflow: auto;
    /*height: 100px;*/
    height: calc(100vh - 20rem);
}

.eds-table {
    overflow: auto;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
}


/* Always show scrollbar */

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
